import React, { useState, useEffect } from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import {Form, Button, Alert} from 'react-bootstrap'

import imgUnCheck from './images/uncheck.svg';
import imgCheck from './images/check.svg';

const LeadForm = function () {
    const [name, setName] = useState('');  
    const [phone, setPhone] = useState('');  
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);  
    const [userMess, setUserMess] = useState(false);  
    const [resStatus, setResStatus] = useState(false);  
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [validPhoneImg, setValidPhoneImg] = useState(null);    
    const keyUpHandler = () => {
        if (!phone) {
            setValidPhoneImg(null);
            return;
        }
        
        isValidPhoneNumber(phone) ? setValidPhoneImg(imgCheck) : setValidPhoneImg(imgUnCheck);
        
        onKeyPressHandler();
    }; 

    const [typingTimer, setTypingTimer] = useState(null);    
    const doneTypingHandler = (e) => {
        const data = { name, phone, message, email };

        fetch('/log.php', {
            method: 'post',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    }
    
    const onKeyPressHandler = (e) => {
        if (typingTimer)
            clearTimeout(typingTimer);
        
        setTypingTimer(setTimeout(doneTypingHandler, 3000));
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        if (!phone || !name || !message || !email) {
            setResStatus('danger');
            setUserMess('Проверьте правильность заполнение формы и повторите попытку!');           
            
            return;
        }
            
        if (isValidPhoneNumber(phone)) {
            const data = { name, phone, message, email };
            setIsSubmitting(true);
            fetch('/contact.php', {
                method: 'post',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((response) => {
                    setIsSubmitting(false);
                    if (response.status === 'success') {
                        window.gtag('event', 'click', {
                            'event_category': 'forma',
                            'event_label': 'click',
                            'value': 'click'
                          });                         
                        window.ym(79233721,'reachGoal','form_button');
                    }
                    
                    if (response.status === 'success' || response.status === 'danger') {
                        setResStatus(response.status);
                        setName('');
                        setPhone('');
                        setMessage('');
                        setValidPhoneImg(null);
                        setUserMess(response.message);
                    }
                });
        } else {
            setResStatus('danger');
            setUserMess('Введен некорректный телефон!');
        }
    };
    
    const nameChangeHandler = (val) => {
        setName(val.target.value);
        onKeyPressHandler();
    }

    return (
                    <Form onSubmit={onSubmit}>
                      {resStatus ? (  <Alert variant={resStatus}>
                                            {userMess}
                                      </Alert>) : ''}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Ваше имя</Form.Label>
                        <Form.Control type="text" placeholder="Ваше имя" onChange={nameChangeHandler} onKeyPress={onKeyPressHandler} value={name} required />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Ваш email</Form.Label>
                        <Form.Control type="email" placeholder="Ваш email" onChange={(e) => setEmail(e.target.value)} onKeyPress={onKeyPressHandler} value={email} required />
                        </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Ваш телефон</Form.Label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          className="form-control"
                          value={phone}
                          defaultCountry={"RU"}
                          international={true}
                          onKeyUp={keyUpHandler}
                          onKeyPress={onKeyPressHandler}
                          onChange={setPhone}/>
                          <img src={validPhoneImg} className="imgCheckPhone" alt ="" />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Ваше сообщение*</Form.Label>
                        <textarea
                        placeholder="Коротко опишите вашу ситуацию..."
                        className="form-control"
                        value={message}
                        onKeyUp={keyUpHandler}
                        onKeyPress={onKeyPressHandler}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        ></textarea>
                    </Form.Group>                      
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Отправка...' : 'Получить консультацию'}
                      </Button>
                    </Form> 
    );
}

export default LeadForm;